<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
//import axios from "axios";

/**
 * Profile component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Community",
    meta: [
      {
        name: "community",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Community",
      user2: null,
      loadingPost: false,
      posts: [],
      finishSync: "",
      jsonpost: "",
      items: [
        {
          text: "Community",
        },
        {
          text: "Community",
          active: true,
        },
      ],
    };
  },
  methods: {
    async loadPosts() {
      this.loadingPost = true;
      var db = firebase.firestore();
      var requete = db.collection("community").orderBy("created_at", "asc");
      requete = requete.where("status", "==", "pending");

      requete.onSnapshot((posts) => {
        console.log(posts.docs.length)
        this.posts = [];
        posts.forEach(async (post) => {
          var postData = post.data();
          //On recupère l'utilisation
          var user = await postData.created_by.get();
          //var userName = user.first_name;
          var parent = null;
          if(postData.parent_id != null){
            parent = await db.collection("community").doc(postData.parent_id).get();
          }
          this.posts.push({
            id: post.id,
            data: postData,
            user: user,
            parent: parent != null ? parent.data() : null
          });
          this.loadingPost = false;
        });
      });
    },
    approvePost(id){
      var db = firebase.firestore();
      db.collection("community").doc(id).update({"status": "approved"});
    },
    refusePost(id){
      var db = firebase.firestore();
      db.collection("community").doc(id).update({"status": "refused"});
    }

  },

  create() {},
  mounted() {
    this.user2 = localStorage.getItem("user2");
    this.loadPosts();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <table class="table table-striped mb-0">
              <thead class="table-light">
                <tr>
                  <th scope="col" width="50px">#</th>
                  <th scope="col">User</th>
                  <th scope="col">Message</th>
                  <th scope="col">Parent Message</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="post in posts" :key="post.id + 'user'">
                  <td class="align-middle">
                    {{ post.id }}
                  </td>
                  <td class="align-middle">
                    {{ post.user.data().first_name }} {{ post.user.data().last_name }} 
                  </td>
                  <td class="align-middle">
                    {{ post.data.content }}
                  </td>
                  <td class="align-middle">
                    {{ post.parent != null ? post.parent.content : "" }}
                  </td>
                  <td class="align-middle">
                    <button type="button" class="btn btn-success" @dblclick="approvePost(post.id)">
                      Approve
                    </button><br/><br/>
                    <button type="button" class="btn btn-danger" @dblclick="refusePost(post.id)">
                      Refuse
                    </button>
                  </td>
                </tr>
              </tbody>
        </table>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
